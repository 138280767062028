const shortenAddress = (address, countLeft, countRight) => {
	if (!address) {
		return '';
	}
	return `${address.substr(0, countLeft)}...${address.substr(
		address.length - countRight,
	)}`;
};

export default shortenAddress;
